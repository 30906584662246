#mainSlider{
	display: none;

	@include query(1030px) {
		display: block;
	}
}

.hero-sing-up {
	position: relative;
	background: url("../images/sing-up-slider.jpg") no-repeat center / cover;
	
	@include query(1024px) {
		background: none;
	}
}

.hero-sing-up__img {
	width: 100%;
		
	@include query(768px) {
	}
}

.hero-sing-up__form {
	margin: 0 auto;
	width: 90%;

	h1 {
		color: #fff;
		font-size: 58px;
    	padding-top: 5%;
		text-align: center;
		@include query(1024px) {
			font-size: 72px;
		}
	}

	h3 {
		color: $color-base;
	}

	input {
		background-color: transparent;
	}

	button {
		text-align: center;
		width: 100%;
	}

  	@include query(1024px) {
		top: $space-out-small;
		left: 5%;
		position: absolute;
		width: 100%;
	    z-index: 10;

	    input {
	    	display: inline;
	    	margin-right: $space-out-small;
	    	padding: 5px;
			width: 25%;
	    }

	    button {
	    	display: inline;
			width: 25%;
		}
	}

	@include query(1300px) {

	    input {
			display: block;
	    }

	    button {
	    	display: block;
		}
	}

}