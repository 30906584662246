// Normalize
// ==========================================================================
// 2. Show the overflow in Edge and IE.
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
// 1. Correct the inheritance and scaling of font size in all browsers.
// 2. Correct the odd `em` font sizing in all browsers.
pre {
  font-family: monospace, monospace; // 1
  font-size: 1em; // 2
}

// 1. Remove the bottom border in Firefox 39-.
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
abbr[title] {
  border-bottom: none; // 1
  text-decoration: underline; // 2
  text-decoration: underline dotted; // 2
}

// Prevent the duplicate application of `bolder` by the next rule in Safari 6.
b,
strong {
  font-weight: inherit;
}

// Add the correct font weight in Chrome, Edge, and Safari.
b,
strong {
  font-weight: bolder;
}

// 1. Correct the inheritance and scaling of font size in all browsers.
// 2. Correct the odd `em` font sizing in all browsers.
code,
kbd,
samp {
  font-family: monospace, monospace; // 1
  font-size: 1em; // 2
}

// Add the correct font style in Android 4.3-.
dfn {
  font-style: italic;
}

// Add the correct background and color in IE 9-.
mark {
  background-color: #ff0;
  color: #000;
}

// Add the correct font size in all browsers.
small {
  font-size: 80%;
}

// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

q {
  font-style: italic;
}

// ==========================================================================
// Most used text
p,
li,
dd,
dt {
  font-size: 16px;

  // @include query(720px) {
  //   font-size: 18px;
  // } 
}

p {
  margin-buttom: $space-out-medium;
}