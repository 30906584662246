// 1. Remove the gray background on active links in IE 10.
// 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
// 3. Remove the unwanted box around FAB buttons More info: http://goo.gl/IPwKi
a {
    background-color: transparent; // 1
    color: $color-base;
    -webkit-text-decoration-skip: objects; // 2
    -webkit-tap-highlight-color: transparent; // 3
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-decoration: none;
    transition: all .3s; 
    
    &:hover {        
        color: $color-cta;
        cursor: pointer;
    }
}

// Remove the outline on focused links when they are also active or hovered
// in all browsers (opinionated).
a:active,
a:hover {
    outline-width: 0;
}