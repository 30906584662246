 .cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

}

.cards__item {
    display: flex;
    width: 100%;
    margin: 1%;
    @media(min-width: 400px) {
        width: 48%;
    }
    @media(min-width: 600px) {
        width: 31.3%;
    }
    @media(min-width: 900px) {
        width: 23%;
    }
    
    @media(min-width: 1200px) {
        width: 18%;
    }
}

.card {
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
        &:hover {
            .card__image {
                filter: contrast(100%);
            }
        }
}

.card__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
}

.card__image {
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    filter: contrast(70%);
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);
    width: 50%;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: $color-base;
    &::before {
        content: "";
        display: block;
        padding-top: 56.25%; // 16:9 aspect ratio
    }
    @media(min-width: 40rem) {
        &::before {
            padding-top: 66.6%; // 3:2 aspect ratio
        }
    }
}

.card__image--support {
    mask-image: url(../images/support.svg);
}

.card__image--login {
    mask-image: url(../images/login.svg);
}

.card__image--reports {
    mask-image: url(../images/report.svg);
}

.card__image--software {
    mask-image: url(../images/3d-printing-software.svg);
}

.card__image--websites {
    mask-image: url(../images/responsive.svg);
}

.card__image--casino {
    mask-image: url(../images/chip.svg);
}

.card__image--phone {
    mask-image: url(../images/smartphone.svg);
}

.card__image--track {
    mask-image: url(../images/report.svg);
}

.card__image--users {
    mask-image: url(../images/user.svg);
}

.card__image--dealer {
    mask-image: url(../images/dealer.svg);
}

.card__image--racebook {
    mask-image: url(../images/horse.svg);
}

.card__image--Secured {
    mask-image: url(../images/secure-shield.svg);
}

.card__image--pay {
    mask-image: url(../images/credit-card.svg);
}

.card__image--sport {
    mask-image: url(../images/strategy.svg);
}

.card__title {
    color: #696969;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-align:center;
    text-transform: uppercase;
}