.form-cta{
	padding-bottom: 5%;
}

.form-cta .form-cta__input{
    display: inline-block;
    padding: 15px 30px 0 0;
	width: 48%;

	.form-cta .form-cta__input input:focus ~ .row-effect{
	    width: 95%;
	}
}