.next-maches__team,
.next-maches__date {
	color: #fff;
	margin: 0;
	width: 70%;
}

.next-maches__score {
	display: inline-block;
	color: $color-base;
	margin: 0;
	padding-right: 3%;
    text-align: right;
    width: 30%;	
}