.sidebar-support {
	background: url("../images/sing-up-slider.jpg") no-repeat center / cover;
	height: 200px;
	
	@include query(1000px) {
		height: initial;		
	}

	div {
		height: 100%;
		position: relative;

		a {
			bottom: 25%;
			border-bottom: 2px solid $color-base;
			color: #fff;
			position: absolute;

			@include query(1000px) {
				bottom: 10%;						
			}
		}
	}
}