#rwd-table {
  display: block;
}

#rwd-table tbody {
  border: none;
}

#rwd-table tbody,
#rwd-table th,
#rwd-table td,
#rwd-table tr,
#rwd-table tfoot {
  display: block;
}

#rwd-table thead {
  display: none;
}


#rwd-table tr {
  float: left;
  width: 100%;
  margin: 0 0 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  border-top: 5px solid #f42027;
}

#rwd-table tr td {
  padding: .5em .5em .5em 50%;
  float: left;
  width: 100%;
}

#rwd-table tr td:before {
  width: 100%;
  display: block;
  float: left;
  padding: .5em .5em .5em 0;
  clear: both;
  margin: -.5em 0 0 -100%;
  font-size: 1.125em;
}

@media screen and (min-width: 600px) {
  #rwd-table {
    display: table;
    border: none;
  }
  
  #rwd-table th,
  #rwd-table td {
    display: table-cell;
  }
  
  #rwd-table tr {
    display: table-row;
    border: none;
    border-bottom: 1px solid #eee;
    float: none;
    margin: 0;
  }
  #rwd-table tr td {
    padding: .75em 0 0.75em .25em;
    float: none;
    width: auto;
  }
  #rwd-table tr td:before {
    padding: 0 !important;
  }
  #rwd-table thead {
    display: table-header-group;
  }
  #rwd-table tbody,
  #rwd-table tfoot {
    display: table-row-group;
  }
  #rwd-table tr:nth-of-type(even) {
    background: none;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font: 1em/1.5 "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  background-color: #efefef;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  color: #4f4f4f;
  margin: 0;
  min-height: 100%;
  position: relative;
  width: 100%;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

article,
aside,
footer,
header,
nav,
section,
figcaption,
figure,
main,
details,
menu {
  display: block;
}

figure {
  margin: 1em 40px;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

button {
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  color: #fff;
  font-family: 'PT Sans Narrow',sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.42rem;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
}

button:hover {
  cursor: pointer;
}

template {
  display: none;
}

textarea {
  resize: none;
  overflow: hidden;
}

h1,
h2,
h3,
h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

h2 {
  color: #4f4f4f;
  margin: 4rem 0;
}

h3,
h4 {
  color: #fff;
}

h1 {
  color: #fff;
  font-size: 28px;
}

@media only screen and (min-width: 624px) {
  h1 {
    font-size: 38px;
  }
}

h2 {
  font-size: 30px;
}

@media only screen and (min-width: 400px) {
  h2 {
    font-size: 46px;
  }
}

h3 {
  font-size: 20px;
}

@media only screen and (min-width: 400px) {
  h3 {
    font-size: 24px;
  }
}

h4 {
  font-size: 18px;
}

@media only screen and (min-width: 400px) {
  h4 {
    font-size: 20px;
  }
}

h5 {
  font-size: 14px;
}

@media only screen and (min-width: 400px) {
  h5 {
    font-size: 16px;
  }
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

img {
  width: inherit;
  /*IE8*/
  max-width: 100%;
  height: auto;
}

a {
  background-color: transparent;
  color: #f42027;
  -webkit-text-decoration-skip: objects;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-decoration: none;
  transition: all .3s;
}

a:hover {
  color: #ba0f1c;
  cursor: pointer;
}

a:active,
a:hover {
  outline-width: 0;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

video {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
}

video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

q {
  font-style: italic;
}

p,
li,
dd,
dt {
  font-size: 16px;
}

p {
  margin-buttom: 2rem;
}

form {
  text-align: right;
}

input {
  border: 2px solid #fff;
  color: #000;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 5px;
  padding: 9px;
  width: 100%;
}

@media only screen and (min-width: 600px) {
  input {
    font-size: 14px;
    width: 200px;
  }
}

input::placeholder {
  color: #869791;
  opacity: 1;
  /* Firefox */
}

button {
  background-color: #D4D4D4;
  color: #070a14;
  padding: 7px;
  text-align: center;
  transition: .2s;
  width: 100%;
}

button:hover {
  background-color: #B2E6CB;
}

@media only screen and (min-width: 600px) {
  button {
    width: 100px;
  }
}

#rwd-table tbody tr td:nth-of-type(1):before {
  content: "Game";
}

@media screen and (min-width: 600px) {
  #rwd-table tbody tr td:nth-of-type(1):before {
    content: '';
  }
}

#rwd-table tbody tr td:nth-of-type(2):before {
  content: "Start at";
}

@media screen and (min-width: 600px) {
  #rwd-table tbody tr td:nth-of-type(2):before {
    content: '';
  }
}

#rwd-table tbody tr td:nth-of-type(3):before {
  content: "Score";
}

@media screen and (min-width: 600px) {
  #rwd-table tbody tr td:nth-of-type(3):before {
    content: '';
  }
}

#rwd-table tbody tr td:nth-of-type(4):before {
  content: "Broadcast";
}

@media screen and (min-width: 600px) {
  #rwd-table tbody tr td:nth-of-type(4):before {
    content: '';
  }
}

th,
td {
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th {
  padding: 10px;
}

tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

tbody tr:last-of-type {
  border: 0;
}

tbody tr:hover {
  background-color: #eae8e8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transform: translate3d(0, -2px, 0);
}

thead {
  background: #f42027;
  color: #fff;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

nav {
  background-color: #070a14;
  padding: 1rem 0;
  transition: transform 1s;
  z-index: 10;
}

@media only screen and (min-width: 980px) {
  nav {
    position: relative;
  }
}

.menu-icon {
  display: block;
  margin: 2rem;
  width: 36px;
}

@media only screen and (min-width: 900px) {
  .menu-icon {
    display: none;
  }
}

.menu-icon:before, .menu-icon:after {
  background-color: #fff;
  content: '';
  display: block;
  height: 4px;
  transition: all .2s ease-in-out;
}

.menu-icon:before {
  box-shadow: 0 10px 0 #fff;
  margin-bottom: 16px;
}

.menu-icon-x:before {
  box-shadow: 0 0 0 #fff;
  transform: translateY(10px) rotate(45deg);
}

.menu-icon-x:after {
  transform: translateY(-10px) rotate(-45deg);
}

.nav-logo {
  width: 50%;
  margin: 1.25rem;
}

@media only screen and (min-width: 600px) {
  .nav-logo {
    width: 30%;
  }
}

@media only screen and (min-width: 900px) {
  .nav-logo {
    width: 15%;
  }
}

.nav-items {
  background-color: rgba(7, 10, 20, 0.95);
  padding-bottom: 1rem;
  position: absolute;
  text-align: left;
  top: 110px;
  transition: transform 1.5s;
  height: 100%;
  width: 75%;
  z-index: 8;
}

@media only screen and (min-width: 900px) {
  .nav-items {
    margin-left: 10px;
    padding-right: 2%;
    position: relative;
    text-align: right;
    top: 0;
    width: 77%;
  }
}

.nav-items a {
  border-bottom: 1px solid #fff;
  color: #fff;
  display: block;
  padding: 10px 0 10px 35px;
}

@media only screen and (min-width: 900px) {
  .nav-items a {
    display: inline-block;
    margin-left: 10px;
    padding: 30px 0 5px 0;
  }
}

@media only screen and (min-width: 1024px) {
  .nav-items a {
    font-size: 18px;
    margin-left: 20px;
  }
}

.nav-items a:hover {
  border-bottom: 2px solid #f42027;
}

.nav-display {
  transform: translate(-500px, 0);
}

@media only screen and (min-width: 500px) {
  .nav-display {
    transform: translate(-700px, 0);
  }
}

@media only screen and (min-width: 700px) {
  .nav-display {
    transform: translate(-910px, 0);
  }
}

@media only screen and (min-width: 900px) {
  .nav-display {
    transform: translate(0, 0);
  }
}

.l-content {
  margin: 0 auto;
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .l-content {
    width: 70%;
  }
}

@media only screen and (min-width: 1200px) {
  .l-content {
    width: 80%;
  }
}

.l-content-sidebar {
  margin: 0 auto;
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .l-content-sidebar {
    width: 30%;
  }
}

@media only screen and (min-width: 1200px) {
  .l-content-sidebar {
    width: 20%;
  }
}

.l-group:before, .l-group:after {
  content: " ";
  display: table;
}

.l-group:after, .l-group:after {
  clear: both;
}

.l-padding {
  padding-left: 5%;
}

.group {
  margin: 0 auto;
  max-width: 90%;
}

.make-hidden {
  display: none;
}

.make-visible {
  display: initial;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.flex-row--between {
  justify-content: space-between;
}

footer {
  background-color: #ba0f1c;
  color: #fff;
  padding: 5px 0;
  text-align: center;
}

header {
  background-color: #ba0f1c;
  color: #fff;
  padding: 1rem;
  text-align: center;
  z-index: 9;
}

header p {
  margin: 0;
}

.btn {
  border-radius: 25px;
  color: #fff;
  display: inline-block;
  transition: all .3s;
}

.btn--principal {
  background-color: #f42027;
  border-radius: 25px;
  margin: 1.25rem 0;
  padding: 1rem 1.25rem;
}

.btn--principal:hover {
  background-color: #ba0f1c;
  color: #fff;
}

.btn {
  border-radius: 25px;
  color: #fff;
  display: inline-block;
  transition: all .3s;
}

.btn--secundary {
  background-color: transparent;
  border: 2px solid #f42027;
  padding: 5px 30px;
}

.btn--secundary:hover {
  background-color: #ba0f1c;
  border: 2px solid #ba0f1c;
  color: #fff;
}

#mainSlider {
  display: none;
}

@media only screen and (min-width: 1030px) {
  #mainSlider {
    display: block;
  }
}

.hero-sing-up {
  position: relative;
  background: url("../images/sing-up-slider.jpg") no-repeat center/cover;
}

@media only screen and (min-width: 1024px) {
  .hero-sing-up {
    background: none;
  }
}

.hero-sing-up__img {
  width: 100%;
}

.hero-sing-up__form {
  margin: 0 auto;
  width: 90%;
}

.hero-sing-up__form h1 {
  color: #fff;
  font-size: 58px;
  padding-top: 5%;
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .hero-sing-up__form h1 {
    font-size: 72px;
  }
}

.hero-sing-up__form h3 {
  color: #f42027;
}

.hero-sing-up__form input {
  background-color: transparent;
}

.hero-sing-up__form button {
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .hero-sing-up__form {
    top: 1.25rem;
    left: 5%;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .hero-sing-up__form input {
    display: inline;
    margin-right: 1.25rem;
    padding: 5px;
    width: 25%;
  }
  .hero-sing-up__form button {
    display: inline;
    width: 25%;
  }
}

@media only screen and (min-width: 1300px) {
  .hero-sing-up__form input {
    display: block;
  }
  .hero-sing-up__form button {
    display: block;
  }
}

.sidebar--dark {
  background-color: #070a14;
  color: #fff;
}

.sidebar__header {
  margin: 0 auto;
  max-width: 90%;
  padding: 1rem 0;
}

.sidebar__header a,
.sidebar__header h4 {
  display: inline-block;
}

.sidebar__header h4 {
  margin-right: 1.25rem;
}

.sidebar__item {
  border-top: 1px solid #fff;
  padding: 1rem 0;
}

.sidebar__item-content {
  margin: 0 auto;
  max-width: 90%;
}

.popular-news__img {
  width: 24%;
}

.popular-news__img img {
  border-radius: 10px;
  height: 85%;
  width: 85%;
}

@media only screen and (min-width: 1000px) {
  .popular-news__img img {
    height: 60px;
    width: 60px;
  }
}

.popular-news__texts {
  color: #fff;
  width: 73%;
}

.popular-news__texts h5 {
  margin: 0;
  font-size: 14px;
}

.popular-news__texts p {
  margin-top: 0;
  font-size: 14px;
}

@media only screen and (min-width: 1000px) {
  .all-news__title {
    float: left;
    margin: 0;
  }
}

.news-article {
  color: #4f4f4f;
  position: relative;
}

@media only screen and (min-width: 425px) {
  .news-article {
    width: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  .news-article {
    width: 25%;
  }
}

.news-article:nth-child(even) {
  background-color: #fff;
}

.news-article:hover {
  color: #4f4f4f;
}

.news-article__img {
  margin-bottom: 2rem;
  max-height: 200px;
  width: 100%;
}

.news-article__date {
  color: #f42027;
  margin-bottom: 1.25rem;
}

.news-article__text {
  text-transform: uppercase;
  margin-bottom: 4rem;
}

.news-article__icon-filter {
  background-color: red;
  border-radius: 50%;
  height: 60px;
  position: absolute;
  right: 10%;
  top: 40%;
  width: 60px;
}

@media only screen and (min-width: 375px) {
  .news-article__icon-filter {
    top: 48%;
  }
}

@media only screen and (min-width: 425px) {
  .news-article__icon-filter {
    top: 25%;
  }
}

@media only screen and (min-width: 650px) {
  .news-article__icon-filter {
    top: 45%;
  }
}

@media only screen and (min-width: 1024px) {
  .news-article__icon-filter {
    top: 20%;
  }
}

@media only screen and (min-width: 1200px) {
  .news-article__icon-filter {
    top: 35%;
  }
}

@media only screen and (min-width: 1440px) {
  .news-article__icon-filter {
    top: 40%;
  }
}

#owl-carousel-espn .news-article img,
#owl-carousel-bbc .news-article img,
#owl-carousel-fox .news-article img,
#owl-carousel-bible .news-article img {
  display: inherit;
  width: initial;
  height: initial;
}

.sidebar-support {
  background: url("../images/sing-up-slider.jpg") no-repeat center/cover;
  height: 200px;
}

@media only screen and (min-width: 1000px) {
  .sidebar-support {
    height: initial;
  }
}

.sidebar-support div {
  height: 100%;
  position: relative;
}

.sidebar-support div a {
  bottom: 25%;
  border-bottom: 2px solid #f42027;
  color: #fff;
  position: absolute;
}

@media only screen and (min-width: 1000px) {
  .sidebar-support div a {
    bottom: 10%;
  }
}

.news-videos iframe {
  height: 400px;
}

.news-videos__last {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .news-videos__last {
    width: 50%;
  }
}

@media only screen and (min-width: 1200px) {
  .news-videos__last {
    width: 70%;
  }
}

@media only screen and (min-width: 1200px) {
  .news-videos__last:last-child {
    width: 29%;
  }
}

.next-maches__team,
.next-maches__date {
  color: #fff;
  margin: 0;
  width: 70%;
}

.next-maches__score {
  display: inline-block;
  color: #f42027;
  margin: 0;
  padding-right: 3%;
  text-align: right;
  width: 30%;
}

.sidebar-about-us {
  background: url("../images/sidebar-about-us.jpg") no-repeat center/cover;
}

.sidebar-about-us p {
  color: #fff;
}

.memebership {
  box-shadow: 6px 8px 28px #9b9b9b;
  color: #070a14;
  margin-bottom: 4rem;
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .memebership {
    width: 30%;
  }
}

.memebership__tittle p {
  color: #fff;
  margin: 0;
  font-size: 48px;
  padding: 2rem;
}

.memebership__price,
.memebership__price-amount {
  font-weight: 700;
  margin: 0;
}

.memebership__price {
  font-size: 40px;
  padding-top: 1rem;
}

.memebership__benefits {
  border-bottom: 1px solid #4f4f4f;
  padding: 10px 0;
}

.memebership__tittle--bronze {
  background: url("../images/memebership-bronze.jpg") no-repeat center/cover;
}

.memebership__tittle--silver {
  background: url("../images/memebership-silver.jpg") no-repeat center/cover;
}

.memebership__tittle--gold {
  background: url("../images/memebership-gold.jpg") no-repeat center/cover;
}

.all-news__filter p {
  display: inline-block;
  font-weight: 700;
  margin-right: 1.25rem;
}

@media only screen and (min-width: 1000px) {
  .all-news__filter p {
    font-size: 18px;
    margin-left: 1.25rem;
    margin-right: 0;
    padding-top: 2rem;
  }
  .all-news__filter p a {
    color: #4f4f4f;
  }
  .all-news__filter p:hover {
    color: #f42027;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1000px) {
  .all-news__filter {
    float: right;
  }
}

.all-news__filter .current a {
  color: #f42027;
}

.all-news__filter .tabs_item {
  display: none !important;
}

.all-news__filter .tabs_item:first-child {
  display: block;
}

.faq {
  border-bottom: 1px solid #ba0f1c;
  margin-bottom: 4rem;
  padding: 1rem 1rem 1rem 0;
  position: relative;
}

.faq-open {
  cursor: pointer;
  display: block;
  padding: 0px;
}

.expanded {
  transition: all .3s ease-in-out;
}

.faq-question {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.faq-answer {
  display: none;
}

.plus-minus-toggle {
  cursor: pointer;
  height: 15px;
  width: 15px;
  position: absolute;
  top: 15px;
  right: 10px;
}

@media only screen and (min-width: 768px) {
  .plus-minus-toggle {
    height: 21px;
    width: 21px;
    top: 25px;
    right: 18px;
  }
}

.plus-minus-toggle:before, .plus-minus-toggle:after {
  background: #4f4f4f;
  content: '';
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
  transition: transform .5s ease;
}

@media only screen and (min-width: 768px) {
  .plus-minus-toggle:before, .plus-minus-toggle:after {
    width: 22px;
  }
}

.plus-minus-toggle:after {
  transform-origin: center;
}

.plus-minus-toggle.collapsed:after {
  transform: rotate(90deg);
}

.plus-minus-toggle.collapsed:before {
  transform: rotate(180deg);
}

.form {
  display: block;
  overflow: hidden;
}

.form .row {
  position: relative;
  width: 90%;
  margin: 15px auto 30px;
  padding: 10px 0;
}

.form .row input,
.form .row textarea {
  display: block;
  width: 100%;
  background: transparent;
  padding: 10px 0;
  color: #444;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 -2px #cec9c9 inset;
  border: none;
  border-radius: 0px;
  outline: 0;
}

.form .row input[type="password"],
.form .row textarea[type="password"] {
  letter-spacing: 7px;
}

.form .row input:placeholder-shown:valid ~ .label,
.form .row textarea:placeholder-shown:valid ~ .label {
  top: 50%;
  font-size: 16px;
  color: #4f4f4f;
}

.form .row input:placeholder-shown:focus ~ .label,
.form .row input:invalid ~ .label,
.form .row input:valid ~ .label,
.form .row textarea:placeholder-shown:focus ~ .label,
.form .row textarea:invalid ~ .label,
.form .row textarea:valid ~ .label {
  top: 0;
  left: 0;
  font-size: 12px;
}

.form .row input:placeholder-shown:focus ~ .label,
.form .row textarea:placeholder-shown:focus ~ .label {
  color: #f42027;
}

.form .row input:invalid ~ .label,
.form .row textarea:invalid ~ .label {
  color: #F44336;
}

.form .row input:valid ~ .label,
.form .row textarea:valid ~ .label {
  color: #00E676;
}

.form .row input:focus ~ .row-effect,
.form .row textarea:focus ~ .row-effect {
  width: 100%;
  left: 0;
}

.form .row input:placeholder-shown:focus ~ .row-effect,
.form .row textarea:placeholder-shown:focus ~ .row-effect {
  background-color: #f42027;
}

.form .row input:invalid ~ .row-effect,
.form .row textarea:invalid ~ .row-effect {
  background-color: #F44336;
}

.form .row input:valid:not(:placeholder-shown) ~ .row-effect,
.form .row textarea:valid:not(:placeholder-shown) ~ .row-effect {
  background-color: #00E676;
}

.form .row input:not(:focus):invalid ~ p,
.form .row textarea:not(:focus):invalid ~ p {
  left: 0;
}

.form .row .row-effect {
  position: absolute;
  height: 3px;
  width: 0;
  left: 50%;
  transition: all 0.2s;
}

.form .row .label {
  position: absolute;
  left: 10px;
  font-family: arial;
  font-weight: bold;
  transform: translateY(-50%);
  transition: all 0.2s;
}

.form .row .input-error {
  position: absolute;
  left: -180px;
  bottom: -7px;
  font-family: arial;
  font-size: 12px;
  color: #F44336;
  transition: all 0.2s;
}

.form .btn {
  display: block;
  width: 20%;
  margin: 30px auto 0;
  background-color: #f42027;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s;
}

.contactUs {
  background: url("../images/contact-us.jpg") no-repeat center/cover;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards__item {
  display: flex;
  width: 100%;
  margin: 1%;
}

@media (min-width: 400px) {
  .cards__item {
    width: 48%;
  }
}

@media (min-width: 600px) {
  .cards__item {
    width: 31.3%;
  }
}

@media (min-width: 900px) {
  .cards__item {
    width: 23%;
  }
}

@media (min-width: 1200px) {
  .cards__item {
    width: 18%;
  }
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.card:hover .card__image {
  filter: contrast(100%);
}

.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
}

.card__image {
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  filter: contrast(70%);
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  width: 50%;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #f42027;
}

.card__image::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}

@media (min-width: 40rem) {
  .card__image::before {
    padding-top: 66.6%;
  }
}

.card__image--support {
  mask-image: url(../images/support.svg);
}

.card__image--login {
  mask-image: url(../images/login.svg);
}

.card__image--reports {
  mask-image: url(../images/report.svg);
}

.card__image--software {
  mask-image: url(../images/3d-printing-software.svg);
}

.card__image--websites {
  mask-image: url(../images/responsive.svg);
}

.card__image--casino {
  mask-image: url(../images/chip.svg);
}

.card__image--phone {
  mask-image: url(../images/smartphone.svg);
}

.card__image--track {
  mask-image: url(../images/report.svg);
}

.card__image--users {
  mask-image: url(../images/user.svg);
}

.card__image--dealer {
  mask-image: url(../images/dealer.svg);
}

.card__image--racebook {
  mask-image: url(../images/horse.svg);
}

.card__image--Secured {
  mask-image: url(../images/secure-shield.svg);
}

.card__image--pay {
  mask-image: url(../images/credit-card.svg);
}

.card__image--sport {
  mask-image: url(../images/strategy.svg);
}

.card__title {
  color: #696969;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
}

/*------------------------------------*\
    MATERIAL PHOTO GALLERY
\*------------------------------------*/
.m-p-g {
  margin: 0 auto;
}

.m-p-g__thumbs-img {
  margin: 0;
  float: left;
  vertical-align: bottom;
  cursor: pointer;
  z-index: 1;
  position: relative;
  opacity: 0;
  filter: brightness(100%);
  -webkit-tap-highlight-color: transparent;
  will-change: opacity, transform;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.m-p-g__thumbs-img.active {
  z-index: 50;
}

.m-p-g__thumbs-img.layout-completed {
  opacity: 1;
}

.m-p-g__thumbs-img.hide {
  opacity: 0;
}

.m-p-g__thumbs-img:hover {
  filter: brightness(110%);
}

.m-p-g__fullscreen {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: transparent;
  visibility: hidden;
  transition: background 0.25s ease-out, visibility 0.01s 0.5s linear;
  will-change: background, visibility;
  backface-visibility: hidden;
}

.m-p-g__fullscreen.active {
  transition: background .25s ease-out, visibility .01s 0s linear;
  visibility: visible;
  background: rgba(0, 0, 0, 0.95);
}

.m-p-g__fullscreen-img {
  pointer-events: none;
  position: absolute;
  transform-origin: left top;
  top: 50%;
  left: 50%;
  max-height: 100vh;
  max-width: 100%;
  visibility: hidden;
  will-change: visibility;
  transition: opacity 0.5s ease-out;
}

.m-p-g__fullscreen-img.active {
  visibility: visible;
  opacity: 1 !important;
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.5s ease-out;
}

.m-p-g__fullscreen-img.almost-active {
  opacity: 0;
  transform: translate3d(0, 0, 0) !important;
}

.m-p-g__controls {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  height: 20vh;
  background: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 0.55) 100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.m-p-g__controls.active {
  opacity: 1;
  visibility: visible;
}

.m-p-g__controls-close, .m-p-g__controls-arrow {
  appearance: none;
  border: none;
  background: none;
}

.m-p-g__controls-close:focus, .m-p-g__controls-arrow:focus {
  outline: none;
}

.m-p-g__controls-arrow {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 20%;
  height: 100vh;
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  opacity: 0;
}

.m-p-g__controls-arrow:hover {
  opacity: 1;
}

.m-p-g__controls-arrow--prev {
  left: 0;
  padding-left: 3vw;
  justify-content: flex-start;
}

.m-p-g__controls-arrow--next {
  right: 0;
  padding-right: 3vw;
  justify-content: flex-end;
}

.m-p-g__controls-close {
  position: absolute;
  top: 3vh;
  left: 3vw;
  z-index: 5;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.m-p-g__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.07);
  transition: all .25s ease-out;
}

.m-p-g__btn:hover {
  background: rgba(255, 255, 255, 0.15);
}

.m-p-g__alertBox {
  position: fixed;
  z-index: 999;
  max-width: 700px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 25px;
  border-radius: 3px;
  text-align: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.23), 0 10px 40px rgba(0, 0, 0, 0.19);
  color: grey;
}

.m-p-g__alertBox h2 {
  color: red;
}

.m-p-g__controls-arrow:hover,
.m-p-g__controls-close:hover {
  background-color: transparent;
}

.form-cta {
  padding-bottom: 5%;
}

.form-cta .form-cta__input {
  display: inline-block;
  padding: 15px 30px 0 0;
  width: 48%;
}

.form-cta .form-cta__input .form-cta .form-cta__input input:focus ~ .row-effect {
  width: 95%;
}
