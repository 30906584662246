// Micro Clearfix Hack
// --------------------
// Based on Nicholas Gallagher clearfix hack: http://nicolasgallagher.com/micro-clearfix-hack/
// Clearfix: contain floats
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    `contenteditable` attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that receive the `clearfix` class.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
@mixin micro-clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after,
    &:after {
        clear: both;
    }
}

// Query
// $breakpoint: puede ser una variable o un valor.
// 
// .selector {
//  @include query($var) {
//     a { color: pink; }
//  }    
// }
@mixin query($breakpoint) {
    @media only screen and ("min-width": $breakpoint) {
        @content;
    }
}

// responsive table mixins
@mixin rwd-first {
    display: block;
  

  tbody {
    border: none;
  }
        tbody, 
        th, 
        td, 
        tr, 
        tfoot {
            display: block; 
        }

        thead {
            display: none;
        }
        tr {
            float: left;
            width: 100%;
            margin: 0 0 1em;
            border: 1px solid #ddd;
            border-radius: 4px;
            border-top: 5px solid $color-base;

            td {
                padding: .5em .5em .5em 50%;
                float:left;
                width: 100%;
                &:before {
                    width: 100%;
                    display: block;
                    float: left;
                    padding: .5em .5em .5em 0;
                    clear: both;
                    margin: -.5em 0 0 -100%;
                    font-size: 1.125em;

                }
            }
        }

        tr:nth-of-type(even) {
            //background: #ddd;
            td {
                
                &:before {
                    
                }
            }
        }
}

@mixin rwd-second {
    display: table;
    border: none;

    th, 
    td {
        display: table-cell; 
    }

    tr {
        display: table-row;
        border: none;
        border-bottom: 1px solid #eee;
        float: none;
        margin: 0;
        
        td {
            padding: .75em 0 0.75em .25em;
            float: none;
            width: auto;

            &:before {
                padding: 0 !important;
            }
        }
    }

    thead {
        display: table-header-group;
    }

    tbody,
    tfoot {
        display: table-row-group;
    }

    tr:nth-of-type(even) {
        background:none;
        td {
            
            &:before {
                
            }
        }
    }
}

%responive-tables {
    @include rwd-first;

    @media screen and (min-width: 600px) { 
        @include rwd-second;
        
    }
}

%responive-tables-large {
    @include rwd-first;

    @media screen and (min-width: 1000px) { 
        @include rwd-second;
        
    }
}

@mixin responive-tables($headings...) {
    $list: $headings;

    @each $list-headings in $list {
        $i: index($list, $list-headings);
        tbody tr td:nth-of-type(#{$i}):before { 
            content: $list-headings; 
        }
        
        @media screen and (min-width: 600px) { 
            tbody tr td:nth-of-type(#{$i}):before { 
                content: ''; 
            }
        }

    }
}

@mixin responive-tables-large($headings...) {
    $list: $headings;

    @each $list-headings in $list {
        $i: index($list, $list-headings);
        tbody tr td:nth-of-type(#{$i}):before { 
            content: $list-headings; 
        }
        
        @media screen and (min-width: 1000px) { 
            tbody tr td:nth-of-type(#{$i}):before { 
                content: ''; 
            }
        }

    }
}