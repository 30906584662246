section {
  // padding: $space-large 0;
}

.l-content {
  margin: 0 auto;
  width: 100%;
  
  @include query(1000px) {
    width: 70%;  
  }
  
  @include query(1200px) {
    width: 80%;  
  }
}

.l-content-sidebar {
  margin: 0 auto;
  width: 100%;
  
  @include query(1000px) {    
    width: 30%;  
  }
  @include query(1200px) {
    width: 20%;  
  }
}



.l-group {
    @include micro-clearfix;
}

.l-padding{
  padding-left: 5%;
}

.group {
  margin: 0 auto;
  max-width: 90%;
  
  // @include query(000px) {
  //   padding: 0 $space-base;
  // }
}

.make-hidden {
  display: none;
}

.make-visible {
  display: initial;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.flex-row--between {
  justify-content: space-between;
}