// Normalize
// ==========================================================================
// Add the correct display in IE 9-.
audio,
video {
  display: inline-block;
}

// Add the correct display in iOS 4-7.
audio:not([controls]) {
  display: none;
  height: 0;
}

// Remove the gap between audio, canvas, iframes,
// images, videos and the bottom of their containers:
// https://github.com/h5bp/html5-boilerplate/issues/440
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

// Responsive video
video {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}