.popular-news__img {
	width: 24%;

	img {
		border-radius: $radius-principal;
		height: 85%;
		width: 85%;

		@include query(1000px) {
			height: 60px;
			width: 60px;
		}		
	}
}

.popular-news__texts {
	color: #fff;
	width: 73%;

	h5 {
		margin: 0;
		font-size: 14px;
	}

	p {
		margin-top: 0;
		font-size: 14px;
	}
}