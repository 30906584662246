// Normalize
// ==========================================================================
// Correct the font size and margin on `h1` elements within `section` and
// `article` contexts in Chrome, Firefox, and Safari.
h1,
h2,
h3,
h4 {
  	font-family: $font-base;
  	font-weight: 700;
}

h2 {
	color: $color-contrast;
	margin: $space-out-large 0;
}

h3,
h4 {
	color: #fff;	
}

h1 {
	color: #fff;
	font-size: 28px;

	@include query(624px) {
		font-size: 38px;
	}
}

h2 {
	font-size: 30px;

	@include query(400px) {
		font-size: 46px;
	}
}

h3 {
	font-size: 20px;

	@include query(400px) {
		font-size: 24px;
	}
}

h4 {
	font-size: 18px;

	@include query(400px) {
		font-size: 20px;
	}
}

h5 {
	font-size: 14px;

	@include query(400px) {
		font-size: 16px;
	}
}