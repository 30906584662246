.all-news__filter {

	p {
		display: inline-block;
		font-weight: 700;
		margin-right: $space-out-small;
	
		@include query(1000px) {
			font-size: 18px;
			margin-left: $space-out-small;
			margin-right: 0;
			padding-top: $space-small;

			a{
				color:$color-contrast;
			}

			&:hover {
				color: $color-base;
				cursor: pointer;
			}
		}
	}	
	
	@include query(1000px) {
		float: right;
	}

	.current a {
		color: $color-base;
	}

	.tabs_item {
		display: none !important;
		&:first-child {
			display: block;
		}
	}
}