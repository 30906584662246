// Normalize
// ==========================================================================
// Remove the border on images inside links in IE 10-.
img {
  border-style: none;
}

// Hide the overflow in IE.
svg:not(:root) {
  overflow: hidden;
}

//images responsive
img {
    width: inherit; /*IE8*/
    max-width: 100%;
    height: auto;
}