// == Creado el 08/08/2017 == //

// == Variables == //
$colorError: #F44336;
$colorAccept: #00E676;
$transition: all .2s;



.form {
    display: block;
    overflow: hidden;
    .row {
        position: relative;
        width: 90%;
        margin: 15px auto 30px;
        padding: 10px 0;
        input,
        textarea {
            display: block;
            width: 100%;
            background: transparent;
            padding: 10px 0;
            color: #444;
            font-size: 16px;
            font-weight: bold;
            box-shadow: 0 -2px #cec9c9 inset;
            border: none;
            border-radius: 0px;
            outline: 0;
            &[type="password"] {
                letter-spacing: 7px;
            }
            // == Estilos etiqueta default == //
            &:placeholder-shown:valid ~ .label {
                top: 50%;
                font-size: 16px;
                color: $color-contrast;
            }
            &:placeholder-shown:focus ~ .label,
            &:invalid ~ .label,
            &:valid ~ .label{
                top: 0;
                left: 0;
                font-size: 12px;
            }
            // == Color etiqueta default == //
            &:placeholder-shown:focus ~ .label {
                color: $color-base;
            }
            // == Color etiqueta cuando el input es inválido == //
            &:invalid ~ .label {
                color: $colorError;
            }
            // == Color etiqeuta cuando el input es válido == //
            &:valid ~ .label {
                color: $colorAccept;
            }
            // == Estilos línea default == //
            &:focus ~ .row-effect {
                width: 100%;
                left: 0;
            }
            // == Color línea default == //
            &:placeholder-shown:focus ~ .row-effect {
                background-color: $color-base;
            }
            // == Color línea cuando el input es inválido == //
            &:invalid ~ .row-effect {
                background-color: $colorError;
            }
            // == Color línea cuando el input es válido == //
            &:valid:not(:placeholder-shown) ~ .row-effect {
                background-color: $colorAccept;
            }
            // == Efecto <p> error == //
            &:not(:focus):invalid ~ p{
                left: 0;
            }
        }
        .row-effect {
            position: absolute;
            height: 3px;
            width: 0;
            left: 50%;
            transition: $transition;
        }
        .label {         
            position: absolute;
            left: 10px;
            font-family: arial;
            font-weight: bold;
            transform: translateY(-50%);
            transition: $transition;
        }
        .input-error {
            position: absolute;
            left: -180px;
            bottom: -7px;
            font-family: arial;
            font-size: 12px;
            color: #F44336;
            transition: $transition;
        }
    }
    .btn {
        display: block;
        width: 20%;
        margin: 30px auto 0;
        background-color: $color-base;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        border: none;
        outline: none;
        border-radius: 25px;
        cursor: pointer;
        text-align: center;
        transition: $transition;
    }
}

.contactUs {
    background: url("../images/contact-us.jpg") no-repeat center / cover;
}