.news-videos {
	iframe {
		height: 400px;
	}
}

.news-videos__last {
	width: 100%;
	
	@include query(768px) {
		width: 50%;
	}

	@include query(1200px) {
		width: 70%;
	}
}

.news-videos__last:last-child {

	@include query(1200px) {
		width: 29%;		
	}
}