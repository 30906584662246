form {
	text-align: right;
}

input {
	border: 2px solid #fff;
	color: #000;
	font-size: 12px;
	display: inline-block;
	margin-bottom: 5px;
	padding: 9px;
	width: 100%;

	@include query(600px) {
		font-size: 14px;
		width: 200px;
	}

	&::placeholder {
    color: #869791;
    opacity: 1; /* Firefox */
	}
}

button {
	background-color: $color-cta-btn;
	color: $color-accent;
	padding: 7px;
	text-align: center;
	transition: .2s;
	width: 100%;

	&:hover {
		background-color: #B2E6CB;
	}

	@include query(600px) {
		width: 100px;
	}
}