header {
	background-color: $color-cta;
	color: #fff;
	padding: $space-base;
	text-align: center;
	z-index: 9;
	
	p {
		margin: 0;
	}
}