.memebership {
	box-shadow: 6px 8px 28px #9b9b9b;
	color: $color-accent;
	margin-bottom: $space-out-large;
	text-align: center;
	width: 100%;

	@include query(768px) {
		width: 30%;
	}
}

.memebership__tittle {
	p {
		color: #fff;
		margin: 0;
		font-size: 48px;
		padding: $space-small;
	}
}

.memebership__price,
.memebership__price-amount {
	font-weight: 700;
	margin: 0;
}

.memebership__price {
	font-size: 40px;
	padding-top: $space-base;
}

.memebership__benefits {
	border-bottom: 1px solid $color-contrast;
	padding: 10px 0;
}

.memebership__tittle--bronze {
	background: url("../images/memebership-bronze.jpg") no-repeat center / cover;	
}

.memebership__tittle--silver {
	background: url("../images/memebership-silver.jpg") no-repeat center / cover;	
}

.memebership__tittle--gold {
	background: url("../images/memebership-gold.jpg") no-repeat center / cover;	
}