.sidebar--dark {
	background-color: $color-accent;
  color: #fff;
}

.sidebar__header {
    margin: 0 auto;
  	max-width: 90%;
    padding: $space-base 0;

  	a,
  	h4 {
		display: inline-block;
  	}

  	h4 {
  		margin-right: $space-out-small;
  	}
  	
}

.sidebar__item {
  	border-top: 1px solid #fff;
  	padding: $space-base 0;	
}

.sidebar__item-content {
	margin: 0 auto;
  max-width: 90%;
}