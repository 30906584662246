.all-news__title {
	@include query(1000px) {
		float: left;
		margin: 0;
	}
}

.news-article {
    color: $color-contrast; 
	position: relative;
	
	@include query(425px) {
		width: 50%;
	}

	@include query(1024px) {
		width: 25%;
	}

	&:nth-child(even) {
		background-color: #fff;
	}
    
    &:hover {        
        color: $color-contrast;
    }
}

.news-article__img {
	margin-bottom: $space-out-medium;
	max-height: 200px;
	width: 100%;

}

.news-article__date {
	color: $color-base;
	margin-bottom: $space-out-small;
}

.news-article__text {
	text-transform: uppercase;
	margin-bottom: $space-out-large;	
}

.news-article__icon-filter {
	background-color: red;
	border-radius: 50%;
	height: 60px;
	position: absolute;
    right: 10%;
	top: 40%;
	width: 60px;
	
	@include query(375px) {
		top: 48%;
	}

	@include query(425px) {
		top: 25%;
	}
	
	@include query(650px) {
		top: 45%;
	}

	@include query(1024px) {
		top: 20%;
	}

	@include query(1200px) {
		top: 35%;
	}

	@include query(1440px) {
		top: 40%;
	}
}

#owl-carousel-espn .news-article img,
#owl-carousel-bbc .news-article img,
#owl-carousel-fox .news-article img,
#owl-carousel-bible .news-article img, {
    display: inherit;
    width: initial;
    height: initial;
}