nav {
	background-color: $color-accent;
	padding: $space-base 0;
	transition: transform 1s;
	z-index: 10;	
	@include query(980px) {
		position: relative;
	}
}

.menu-icon {
	display: block;
	margin: $space-out-medium;
	width: 36px;
    @include query(900px) {
		display: none;
    }


	&:before, 
	&:after {
		background-color: #fff;
		content: '';
		display: block;
		height: 4px;
		transition: all .2s ease-in-out;
	}

	&:before {
	    box-shadow: 0 10px 0 #fff;
	    margin-bottom: 16px;
	}
}

.menu-icon-x {
	&:before {
		box-shadow: 0 0 0 #fff;
		transform: translateY(10px) rotate(45deg);
	}
	&:after {
		transform: translateY(-10px) rotate(-45deg);
	}
}

.nav-logo {
	width: 50%;
	margin: $space-out-small;

	@include query(600px) {
		width: 30%;
	}

	@include query(900px) {
		width: 15%;
	}
}

.nav-items {
	background-color: rgba(7, 10, 20, .95);
	padding-bottom: $space-base;
	position: absolute;
	text-align: left;
	top: 110px;
	transition: transform 1.5s;
	height: 100%;
	width: 75%;
	z-index: 8;
	
	@include query(900px) {
		margin-left: 10px;
		padding-right: 2%;
		position: relative;
		text-align: right;
		top: 0;
		width:77%;
	}
	
	a { 
		border-bottom: 1px solid #fff;
		color: #fff;
		display: block;
		padding: 10px 0 10px 35px;

		@include query(900px) {
			display: inline-block;
			margin-left: 10px;
			padding: 30px 0 5px 0;
		}

		@include query(1024px) {
			font-size: 18px;
			margin-left: 20px;
		}
		
		&:hover {
			border-bottom: 2px solid $color-base;
		}
	}
}

.nav-display {
	transform: translate(-500px, 0);

	@include query(500px) {
		transform: translate(-700px, 0);
	}

	@include query(700px) {
		transform: translate(-910px, 0);
	}

	@include query(900px) {
		transform: translate(0, 0);		
	}
}